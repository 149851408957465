import React, { FunctionComponent } from 'react';
import { OrderItemShown } from '../../interfaces/Order';
import OrderStatus from '../../interfaces/OrderStatus';
import TableColProperty from '../shared/interfaces/TableColProperty';
import Table from '../shared/Table';
import Tooltip from '../shared/Tooltip';

//configuration array for the table body columns
const OM_COLUMN_PROPERTIES: TableColProperty[] = [
  { title: '_checkbox_', colMaxWidth: 'auto', colMinWidth: '30px', apiValue: '_checkbox_' },
  { title: 'Order ID', colMaxWidth: 'auto', colMinWidth: 'auto', apiValue: 'id' },
  { title: 'Status & Action', colMaxWidth: 'auto', colMinWidth: '150px', apiValue: 'status' },
  { title: 'Emp. Name', colMaxWidth: 'auto', colMinWidth: 'auto', apiValue: 'user_name' },
  { title: 'Emp. Office', colMaxWidth: 'auto', colMinWidth: 'auto', apiValue: 'zone_name' },
  { title: 'Emp. Unit/Studio', colMaxWidth: 'auto', colMinWidth: 'auto', apiValue: 'unit' },
  { title: 'Product Name', colMaxWidth: 'auto', colMinWidth: 'auto', apiValue: 'product_name' },
  { title: 'Property #1 Name', colMaxWidth: '70px', colMinWidth: 'auto', apiValue: 'propertyName1' },
  { title: 'Property #1 Value', colMaxWidth: '70px', colMinWidth: 'auto', apiValue: 'propertyValue1' },
  { title: 'Property #2 Name', colMaxWidth: '70px', colMinWidth: 'auto', apiValue: 'propertyName2' },
  { title: 'Property #2 Value', colMaxWidth: '70px', colMinWidth: 'auto', apiValue: 'propertyValue2' },
  { title: 'Property #3 Name', colMaxWidth: '70px', colMinWidth: 'auto', apiValue: 'propertyName3' },
  { title: 'Property #3 Value', colMaxWidth: '70px', colMinWidth: 'auto', apiValue: 'propertyValue3' },
  { title: 'Property #4 Name', colMaxWidth: '70px', colMinWidth: 'auto', apiValue: 'propertyName4' },
  { title: 'Property #4 Value', colMaxWidth: '70px', colMinWidth: 'auto', apiValue: 'propertyValue4' },
  { title: 'Property #5 Name', colMaxWidth: '70px', colMinWidth: 'auto', apiValue: 'propertyName5' },
  { title: 'Property #5 Value', colMaxWidth: '70px', colMinWidth: 'auto', apiValue: 'propertyValue5' },
  { title: 'Price', colMaxWidth: 'auto', colMinWidth: 'auto', apiValue: 'price' },
  { title: 'Order Date', colMaxWidth: 'auto', colMinWidth: '150px', apiValue: 'orderDate' },
  { title: 'Delivery Date', colMaxWidth: 'auto', colMinWidth: '150px', apiValue: 'deliveryDate' },
];

export type OrdersManagementTableProps = {
  allSelected: boolean;
  onSelectAllChange: () => void;
  selectedStatus: OrderStatus;
  shownOrders: OrderItemShown[];
  selectItem: (item: OrderItemShown) => void;
};

const OrdersManagementTable: FunctionComponent<OrdersManagementTableProps> = props => {
  return (
    <div className="table-holder mb-6" data-testid="ordersManagementTable">
      <Table
        columnProps={OM_COLUMN_PROPERTIES}
        allSelected={props.allSelected}
        onSelectAllChange={props.onSelectAllChange}
        disableSelectAll={props.selectedStatus.id === '0'}
        tooltipTitle="Select all can be checked only when orders are loaded for specific status"
      >
        {props.shownOrders.map((item, index: number) => (
          <tr key={`pm_row_${index}`} data-testid="order-row">
            {OM_COLUMN_PROPERTIES.map((property, ind) => {
              let displayText = 'N/A';
              switch (property.apiValue) {
                case 'propertyName1':
                case 'propertyName2':
                case 'propertyName3':
                case 'propertyName4':
                case 'propertyName5':
                  const numKey = parseInt(property.apiValue.substring(property.apiValue.length - 1, property.apiValue.length));
                  if (item.product && item.product.properties) {
                    const key_arr: string[] = Object.keys(item.product.properties);
                    if (key_arr[numKey - 1]) {
                      displayText = key_arr[numKey - 1];
                    }
                  }
                  return (
                    <td key={`pm_col_${ind}`} data-testid={`pm_col_${ind}`}>
                      {displayText}
                    </td>
                  );
                case 'propertyValue1':
                case 'propertyValue2':
                case 'propertyValue3':
                case 'propertyValue4':
                case 'propertyValue5':
                  const numVal = parseInt(property.apiValue.substring(property.apiValue.length - 1, property.apiValue.length));
                  if (item.properties) {
                    const val_arr: string[] = Object.values(item.properties);
                    if (val_arr[numVal - 1]) {
                      displayText = val_arr[numVal - 1];
                    }
                  }
                  return (
                    <td key={`pm_col_${ind}`} data-testid={`pm_col_${ind}`}>
                      {displayText}
                    </td>
                  );
                case '_checkbox_':
                  return (
                    <td key={`pm_col_${ind}`} data-testid={`pm_col_${ind}`}>
                      <Tooltip
                        title="Orders can be selected only for specific status"
                        position="right"
                        show={props.selectedStatus.id === '0'}
                      >
                        <label className={`checkbox-container ${props.selectedStatus.id === '0' ? 'disabled' : ''}`}>
                          {' '}
                          <input
                            type="checkbox"
                            data-testid="selectone"
                            checked={item.checked}
                            onChange={() => {
                              props.selectItem(item);
                            }}
                            disabled={props.selectedStatus.id === '0'}
                          />
                          <span className="checkmark"></span>
                        </label>
                      </Tooltip>
                    </td>
                  );
                case 'zone_name':
                  return (
                    <td key={`pm_col_${ind}`} data-testid={`pm_col_${ind}`}>
                      {item.zone.name}
                    </td>
                  );
                case 'user_name':
                  return (
                    <td key={`pm_col_${ind}`} data-testid={`pm_col_${ind}`}>
                      {item.user ? item.user.firstName + ' ' + item.user.lastName : ''}
                    </td>
                  );
                case 'product_name':
                  return (
                    <td key={`pm_col_${ind}`} data-testid={`pm_col_${ind}`}>
                      {item.product.name}
                    </td>
                  );
                case 'orderDate':
                  return (
                    <td key={`pm_col_${ind}`} data-testid={`pm_col_${ind}`}>
                      {item.orderDate &&
                        new Intl.DateTimeFormat('default', { year: 'numeric', month: 'short', day: 'numeric' }).format(
                          new Date(item.orderDate)
                        )}
                    </td>
                  );
                case 'deliveryDate':
                  return (
                    <td key={`pm_col_${ind}`} data-testid={`pm_col_${ind}`}>
                      {item.deliveryDate &&
                        new Intl.DateTimeFormat('default', { year: 'numeric', month: 'short', day: 'numeric' }).format(
                          new Date(item.deliveryDate)
                        )}
                    </td>
                  );
                case 'status':
                  return (
                    <td key={`pm_col_${ind}`} data-testid={`pm_col_${ind}`}>
                      {item.status.name}
                    </td>
                  );
                default:
                  return (
                    <td key={`pm_col_${ind}`} data-testid={`pm_col_${ind}`}>
                      {item[property.apiValue as keyof OrderItemShown]}
                    </td>
                  );
              }
            })}
          </tr>
        ))}
      </Table>{' '}
    </div>
  );
};

export default OrdersManagementTable;
