import React, { FunctionComponent, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useInterceptor } from '../../auth/interceptor';
import Image from '../../interfaces/Image';
import { Product, ProductProperty } from '../../interfaces/product';
import { TimeZone } from '../../interfaces/timezone';
import { ROUTE_PATHS } from '../../utils/constants/route_paths';
interface Params {
  productId: string | undefined;
}

const all_zones: TimeZone = { id: '0', name: 'All Zones', timeZone: '/' };
const initialProduct: Product = {
  id: '0',
  name: '',
  description: '',
  additionalInfo: '',
  properties: [] as ProductProperty[],
  newProduct: false,
  mustHaveLabel: false,
  price: 0,
  priceOnSale: 0,
  deliveryTime: 0,
  images: [] as Image[],
  thumbnail: '',
  availableProducts: 0,
  availableInZones: [] as TimeZone[],
  status: '',
};

const ViewProduct: FunctionComponent = () => {
  const history = useHistory();
  const [axiosApiInstance] = useInterceptor();
  const [product, setProduct] = useState(initialProduct);
  const { productId } = useParams<Params>();
  const [zones, setZones] = useState([] as TimeZone[]);

  useEffect(() => {
    axiosApiInstance.get(`/products/${productId}`).then(
      res => {
        setProduct(res.data);
      },
      () => {
        toast.error('Product can not be loaded');
      }
    );

    //get all available zones
    axiosApiInstance.get(`/settings/available-zones`).then(
      res => {
        setZones([all_zones, ...(res.data as TimeZone[])]);
      },
      () => {
        toast.error('No zones available');
      }
    );
  }, []);

  const closeProduct = () => {
    if (history) {
      history.push(ROUTE_PATHS.PRODUCT);
    }
  };
  const editProduct = () => {
    if (history) {
      history.push(`${ROUTE_PATHS.EDITPRODUCT}/${productId}`);
    }
  };

  return (
    <div>
      {product.id && (
        <div data-testid="viewProduct">
          <div className="mt-5">
            <span className="ml-10 mr-10 font-bold text-gray-700">{'Product Details'}</span>
          </div>
          <div className="mb-10 ml-10 mr-10 border-t-2 border-gray-400"></div>
          <div className="ml-10 mr-10 w-6/6" data-testid="addEdiForm">
            <div className="grid gap-1 grid-cols-4 xl:grid-cols-6">
              <div className={'self-center mb-3'}>
                <span>Product ID:</span>
              </div>
              <div className={'col-span-3 xl:col-span-5'} data-testid="productId">
                <span>{product.id}</span>
              </div>
              <div className="self-center mb-3">
                <span>Product Name:</span>
              </div>
              <div className="col-span-3 xl:col-span-5" data-testid="productName">
                <span>{product.name}</span>
              </div>
              <div className="self-center mb-3">Product Description:</div>
              <div className="col-span-3 xl:col-span-5" data-testid="productDescription">
                <span>{product.description}</span>
              </div>
              <div className="self-center mb-3">Additional info:</div>
              <div className="col-span-3 xl:col-span-5" data-testid="productInfo">
                <span>{product.additionalInfo}</span>
              </div>
              <div className="self-center col-span-1 mb-3">
                <span>Status:</span>
              </div>
              <div className="col-span-3 xl:col-span-5" data-testid="productStatus">
                <span>{product.status}</span>
              </div>
            </div>
            {product.properties &&
              Object.keys(product.properties).map(
                (el, ind) =>
                  Object.values(product.properties)[ind] && (
                    <div key={`property_${ind}`} className="grid gap-1 grid-cols-4 xl:grid-cols-6 mb-3">
                      <div className="self-center">{`Property #${ind + 1}:`}</div>
                      <div className="dropdown w-full col-span-1" data-testid="productPropKey">
                        <span>{el}</span>
                      </div>
                      <div className="flex col-span-2  xl:col-span-4">
                        <span>List of values:</span>
                        <span className="ml-4" data-testid={`productPropValue-${ind}`}>
                          {Object.values(product.properties)[ind]}
                        </span>
                      </div>
                    </div>
                  )
              )}
            <div className="grid gap-1 grid-cols-4 xl:grid-cols-6" data-testid="addEdiForm">
              <div className="self-center col-span-1 mb-3">Cost (Original):</div>
              <div className="col-span-3 xl:col-span-5">
                <span data-testid="productPrice">
                  {product.price} <span className="font-light"> {`   PlaytiCoins`}</span>
                </span>
              </div>
              <div className="self-center mb-3">Cost (On Sale):</div>
              <div className="col-span-3 xl:col-span-5">
                <span data-testid="productPriceOnSale">
                  {product.priceOnSale} <span className="font-light"> {`   PlaytiCoins`}</span>
                </span>
              </div>
              <div className="self-center mb-3">Delivery Time:</div>
              <div className="col-span-3 xl:col-span-5">
                <span data-testid="productDeliveryTime">
                  {product.deliveryTime} <span className="font-light"> {`   Days`}</span>
                </span>
              </div>
              <div className="self-center mb-3">Is &quot;New&quot;?</div>
              <div className="justify-self-start  col-span-3 xl:col-span-5">
                <label className={`checkbox-container justify-start `}>
                  <input
                    className="mr-4 leading-tight"
                    data-testid="newProduct"
                    readOnly
                    type="checkbox"
                    checked={product.newProduct}
                  />
                  <span className="checkmark"></span>
                </label>
              </div>
              <div className="self-center mb-3">Is &quot;Must Have&quot;?</div>
              <div className="justify-self-start col-span-3 xl:col-span-5">
                <label className={`checkbox-container justify-start`}>
                  <input
                    className="mr-4 leading-tight mb-4"
                    data-testid="mustHaveLabel"
                    readOnly
                    type="checkbox"
                    checked={product.mustHaveLabel}
                  />
                  <span className="checkmark"></span>
                </label>
              </div>
              <div className="self-top mb-3">
                <span>Images: </span>
              </div>
              <div className="col-span-3 xl:col-span-5">
                {product.images &&
                  product.images.map((img, ind) => (
                    <div className="grid table-holder gap-2 grid-cols-7 mb-3" key={`prod_img_${ind}`}>
                      <span className="col-span-1"> {`#${ind + 1}${img.mainImage ? ' Main' : ''}`} </span>
                      <img
                        className="col-span-6 w-1/12"
                        data-testid="productImg"
                        src={`https://d3nioszw1lcabk.cloudfront.net/${img.imageKey}`}
                      />
                    </div>
                  ))}
              </div>
              <div className="self-top mb-3">
                <span>Product will be available in:</span>
              </div>
              <div className="col-span-2 xl:col-span-2 border rounded-sm border-gray-700 ">
                <div className="w-full m-2">
                  <label className={`checkbox-container inline-block custom-for-zones `}>
                    <input
                      data-testid="inAllOffices"
                      className="mr-2"
                      type="checkbox"
                      readOnly
                      checked={
                        product.availableInZones && product.availableInZones.length === zones.filter(c => c.id !== '0').length
                      }
                    />
                    <span className="checkmark"></span>
                  </label>
                  <span className="mr-2">All Offices</span>
                  <div className="-ml-1 mr-3 mt-2 border-t border-gray-600" />
                </div>
                {zones
                  .filter(c => c.id !== '0')
                  .map((item: TimeZone, index: number) => (
                    <div key={`z_${item.id}`} className="m-2">
                      <label className={`checkbox-container inline-block custom-for-zones `}>
                        <input
                          data-testid={`check-item-${index}`}
                          className="mr-2"
                          type="checkbox"
                          readOnly
                          checked={product.availableInZones && product.availableInZones.filter(c => c.id === item.id).length > 0}
                        />
                        <span className="checkmark"></span>
                      </label>
                      <span className="mr-2">{item.name}</span>
                    </div>
                  ))}
              </div>
            </div>
          </div>
          <div className="h-20 ml-10 mr-10 ">
            <div className="flex justify-between mt-10">
              <button
                data-testid="eventClose"
                className={'btn-main'}
                onClick={() => {
                  closeProduct();
                }}
              >
                Back to Product catalog
              </button>
              <button
                data-testid="eventEdit"
                className={'btn-main'}
                onClick={() => {
                  editProduct();
                }}
              >
                Edit
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ViewProduct;
