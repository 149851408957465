import React, { FunctionComponent } from 'react';
import pendingGiftsIcon from '../../assets/svg/giftIcon.svg';
import historyIcon from '../../assets/svg/historyIcon.svg';
import { UserItem } from '../../interfaces/user';
import TableColProperty from '../shared/interfaces/TableColProperty';
import Table from '../shared/Table';

//configuration array for the table body columns
export const PCM_COLUMN_PROPERTIES: TableColProperty[] = [
  { title: '_checkbox_', colMaxWidth: 'auto', colMinWidth: '30px', apiValue: '_checkbox_' },
  { title: 'Employee ID', colMaxWidth: 'auto', colMinWidth: 'auto', apiValue: 'id' },
  { title: 'Employee Name', colMaxWidth: 'auto', colMinWidth: 'auto', apiValue: 'fullName' },
  { title: 'Employee Email', colMaxWidth: 'auto', colMinWidth: 'auto', apiValue: 'email' },
  { title: 'Employee Office', colMaxWidth: 'auto', colMinWidth: 'auto', apiValue: 'zone_name' },
  { title: 'Employee Unit/Studio', colMaxWidth: 'auto', colMinWidth: 'auto', apiValue: 'studioUnit_name' },
  { title: 'Current PlaytiCoin Balance', colMaxWidth: 'auto', colMinWidth: 'auto', apiValue: 'currentBalance' },
  { title: 'PlaytiCoins Balance History', colMaxWidth: 'auto', colMinWidth: 'auto', apiValue: 'history' },
  { title: 'Pending Gifts', colMaxWidth: 'auto', colMinWidth: 'auto', apiValue: 'pending_gifts' },
];

export type PlayticoinsTableProps = {
  allSelected: boolean;
  onSelectAllChange: () => void;
  usersList: UserItem[];
  selectItem: (item: UserItem) => void;
  openBalanceHistory: (item: UserItem) => void;
  openPendingGifts: (item: UserItem) => void;
};

const PlayticoinsTable: FunctionComponent<PlayticoinsTableProps> = props => {
  return (
    <div className="table-holder mb-6" data-testid="playticoinsTable">
      <Table
        columnProps={PCM_COLUMN_PROPERTIES}
        allSelected={props.allSelected}
        onSelectAllChange={props.onSelectAllChange}
        disableSelectAll={props.usersList.length === 0}
        tooltipTitle="No users to be selected"
      >
        {props.usersList.map((item, index: number) => (
          <tr key={`pm_row_${index}`} data-testid="playticoin-row">
            {PCM_COLUMN_PROPERTIES.map((property, ind) => {
              const displayText = 'N/A';
              switch (property.apiValue) {
                case 'propertyName1':
                  return (
                    <td key={`pm_col_${ind}`} data-testid={`pm_col_${ind}`}>
                      {displayText}
                    </td>
                  );
                case '_checkbox_':
                  return (
                    <td key={`pm_col_${ind}`} data-testid={`pm_col_${ind}`}>
                      <label className={`checkbox-container`}>
                        {' '}
                        <input
                          type="checkbox"
                          data-testid="selectone"
                          checked={item.checked}
                          onChange={() => {
                            props.selectItem(item);
                          }}
                        />
                        <span className="checkmark"></span>
                      </label>
                    </td>
                  );
                case 'zone_name':
                  return (
                    <td key={`pm_col_${ind}`} data-testid={`pm_col_${ind}`}>
                      {item.zone.name}
                    </td>
                  );
                case 'studioUnit_name':
                  return (
                    <td key={`pm_col_${ind}`} data-testid={`pm_col_${ind}`}>
                      {item.studioUnit ? item.studioUnit?.name : ''}
                    </td>
                  );
                case 'fullName':
                  return (
                    <td key={`pm_col_${ind}`} data-testid={`pm_col_${ind}`}>
                      {item.firstName + ' ' + item.lastName}
                    </td>
                  );
                case 'history':
                  return (
                    <td key={`pm_col_${ind}`} data-testid={`pm_col_${ind}`}>
                      <div className="flex align-middle justify-center">
                        <a
                          className="p-1 cursor-pointer icon"
                          data-testid="history_btn"
                          onClick={() => props.openBalanceHistory(item)}
                        >
                          <img src={historyIcon} />
                        </a>
                      </div>
                    </td>
                  );
                case 'pending_gifts':
                  return (
                    <td key={`pm_col_${ind}`} data-testid={`pm_col_${ind}`}>
                      <div className="flex align-middle justify-center">
                        <a
                          className="p-1 cursor-pointer icon"
                          data-testid="pending_gifts_btn"
                          onClick={() => props.openPendingGifts(item)}
                        >
                          <img src={pendingGiftsIcon} />
                        </a>
                      </div>
                    </td>
                  );
                default:
                  return (
                    <td key={`pm_col_${ind}`} data-testid={`pm_col_${ind}`}>
                      {item[property.apiValue as keyof UserItem]}
                    </td>
                  );
              }
            })}
          </tr>
        ))}
      </Table>
    </div>
  );
};

export default PlayticoinsTable;
