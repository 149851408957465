import React, { FunctionComponent } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import useAuthStore from '../../stores/authStore';
import useSettings from '../../stores/settings';
import { ROUTE_PATHS } from '../../utils/constants/route_paths';
import GuardedRoute from '../../utils/GuardedRoute';
import NotificationsCenter from '../notifications/NotificationsCenter';
import OrdersManagement from '../orders/OrdersManagement';
import PlaytiCoinsManagement from '../playtiCoins/PlaytiCoinsManagement';
import AddProduct from '../products/AddEditProduct';
import ProductManagement from '../products/ProductManagement';
import ViewProduct from '../products/ViewProduct';
import SystemSettings from '../systemSettings/SystemSettings';
import LoadingScreen from './LoadingScreen';
import AddLocation from '../locations/AddLocation';
import Cms from '../cms/CMS';
import AddEditCategory from '../cms/AddEditCategory';

const Routes: FunctionComponent = () => {
  const { isAuthenticated } = useAuthStore();
  const { logged } = useSettings();
  return (
    <div data-testid="routes">
      <Switch>
        <Route
          exact
          path={ROUTE_PATHS.HOME}
          render={() => {
            return <Redirect to={ROUTE_PATHS.PRODUCT} />;
          }}
        />
        <GuardedRoute
          data-testid="product"
          exact
          path={ROUTE_PATHS.PRODUCT}
          render={() => <ProductManagement />}
          auth={isAuthenticated || logged}
          loginRoute={false}
        />
        <GuardedRoute
          data-testid="product"
          exact
          path={ROUTE_PATHS.ADDPRODUCT}
          render={() => <AddProduct isEditProduct={false} />}
          auth={isAuthenticated || logged}
          loginRoute={false}
        />
        <GuardedRoute
          data-testid="product"
          exact
          path={`${ROUTE_PATHS.EDITPRODUCT}/:productId`}
          render={() => <AddProduct isEditProduct={true} />}
          auth={isAuthenticated || logged}
          loginRoute={false}
        />
        <GuardedRoute
          exact
          data-testid="product_view"
          path={`${ROUTE_PATHS.VIEWPRODUCT}/:productId`}
          render={() => <ViewProduct />}
          auth={isAuthenticated || logged}
          loginRoute={false}
        />

        <GuardedRoute
          data-testid="orders"
          exact
          path={ROUTE_PATHS.ORDERS}
          render={() => <OrdersManagement />}
          auth={isAuthenticated || logged}
          loginRoute={false}
        />
        <GuardedRoute
          data-testid="playticoins"
          exact
          path={ROUTE_PATHS.PLAYTICOINS}
          render={() => <PlaytiCoinsManagement />}
          auth={isAuthenticated || logged}
          loginRoute={false}
        />
        <GuardedRoute
          data-testid="notifications"
          exact
          path={ROUTE_PATHS.NOTIFICATIONS}
          render={() => <NotificationsCenter />}
          auth={isAuthenticated || logged}
          loginRoute={false}
        />
        <GuardedRoute
          data-testid="systemSettings"
          path={ROUTE_PATHS.SYSTEMSETTINGS}
          render={() => <SystemSettings />}
          auth={isAuthenticated}
          loginRoute={false}
        />
        <GuardedRoute
          data-testid="cms"
          exact
          path={ROUTE_PATHS.CMS}
          render={() => {
            return <Cms />;
          }}
          auth={isAuthenticated}
          loginRoute={false}
        />
        <GuardedRoute
          data-testid="cms"
          path={ROUTE_PATHS.ADDCATEGORY}
          render={() => {
            return <AddEditCategory isEditCategory={false} />;
          }}
          auth={isAuthenticated || logged}
          loginRoute={false}
        />
        <GuardedRoute
          data-testid="cms"
          path={`${ROUTE_PATHS.ADDSUBCATEGORYORCONTENT}/:parentIdParams`}
          render={() => {
            return <AddEditCategory isEditCategory={false} isSubCategory={true} />;
          }}
          auth={isAuthenticated || logged}
          loginRoute={false}
        />
        <GuardedRoute
          data-testid="cms"
          path={`${ROUTE_PATHS.EDITCATEGORY}/:categoryId/:parentIdParams`}
          render={() => {
            return <AddEditCategory isEditCategory={true} />;
          }}
          auth={isAuthenticated || logged}
          loginRoute={false}
        />
        <GuardedRoute
          data-testid="location"
          path={ROUTE_PATHS.LOCATION}
          render={() => <AddLocation />}
          auth={isAuthenticated}
          loginRoute={false}
        />
        <GuardedRoute
          data-testid="loading"
          path={ROUTE_PATHS.LOGIN}
          render={() => <LoadingScreen />}
          auth={!isAuthenticated}
          loginRoute={true}
        />
      </Switch>
    </div>
  );
};

export default Routes;
