/**
 * Copyrights 2021 Playtika Ltd., all rights reserved to Playtika Ltd.
 * privacy+e17bb14d-edc1-4d26-930d-486fcc1ab8fe@playtika.com
 */

import create, { State } from 'zustand';
import ProductCatalogItem from '../interfaces/product';
import { TimeZone } from '../interfaces/timezone';

interface productStore extends State {
  editProduct: ProductCatalogItem | undefined;
  zones: TimeZone[];
  setEditProduct: (ProductCatalogItem: ProductCatalogItem) => void;
  setZones: (zones: TimeZone[]) => void;
}

// zustand store that returns global setting for the application
const productSettings = create<productStore>(
  (set: any): productStore => ({
    editProduct: undefined,
    zones: [] as TimeZone[],
    setEditProduct: (_editProduct: ProductCatalogItem) => set(() => ({ editProduct: _editProduct })),
    setZones: (_zones: TimeZone[]) => set(() => ({ zones: _zones }))
  })
);

export default productSettings;
