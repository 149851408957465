import React, { FunctionComponent } from 'react';
import { NavLink } from 'react-router-dom';
import { ROUTE_PATHS } from '../../utils/constants/route_paths';

const MainNavigation: FunctionComponent = () => {
  return (
    <div data-testid="main-navigation">
      <nav className="h-12 w-4/6 bg-red text-black text-sm font-bold">
        <ul className="flex items-center justify-between h-full px-8">
          <NavLink
            data-testid="productManagement"
            to={ROUTE_PATHS.PRODUCT}
            activeClassName="text-yellow underline"
            className="font-regular text-white"
          >
            <span>Product Catalog Management</span>
          </NavLink>
          <NavLink
            data-testid="oderSettings"
            to={ROUTE_PATHS.ORDERS}
            className="font-regular text-white"
            activeClassName="text-yellow underline"
          >
            <span>Orders Management</span>
          </NavLink>
          <NavLink
            data-testid="playtiCoinsSettings"
            to={ROUTE_PATHS.PLAYTICOINS}
            className="font-regular text-white "
            activeClassName="text-yellow underline"
          >
            PlaytiCoins Management
          </NavLink>
          <NavLink
            data-testid="notificationsCenterLink"
            to={ROUTE_PATHS.NOTIFICATIONS}
            className="font-regular text-white "
            activeClassName="text-yellow underline"
          >
            Notifications Center
          </NavLink>
          <NavLink
            data-testid="systemSettings"
            to={ROUTE_PATHS.SYSTEMSETTINGS}
            className="font-regular text-white"
            activeClassName="text-yellow underline"
          >
            System Configuration
          </NavLink>
          <NavLink
            data-testid="location"
            to={ROUTE_PATHS.LOCATION}
            className="font-regular text-white"
            activeClassName="text-yellow underline"
          >
            Add Location
          </NavLink>
          <NavLink
            data-testid="cms"
            to={ROUTE_PATHS.CMS}
            className="font-regular text-white"
            activeClassName="text-yellow underline"
          >
            Content Management System
          </NavLink>
        </ul>
      </nav>
    </div>
  );
};

export default MainNavigation;
