import axios, { AxiosResponse } from 'axios';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useInterceptor } from '../../auth/interceptor';
import Image from '../../interfaces/Image';
//store
import { TimeZone } from '../../interfaces/timezone';
import productSettings from '../../stores/productSettings';
import { ROUTE_PATHS } from '../../utils/constants/route_paths';
//components
import ImageList from './AddEditProduct/ImageList';
import ProductInfo from './AddEditProduct/ProductInfoDetails';
import ProductOrder from './AddEditProduct/ProductOrderDetails';
import ProductProperites from './AddEditProduct/PropertyList';

interface Props {
  isEditProduct: boolean;
}
interface Params {
  productId: string | undefined;
}

const AddEditProduct: FunctionComponent<Props> = ({ isEditProduct }) => {
  const history = useHistory();
  const [id, setId] = useState('0');
  const [productName, setProductName] = useState('');
  const [productDescription, setProductDescription] = useState('');
  const [additionalInfo, setAdditionalInfo] = useState('');
  const [productStatus, setProductStatus] = useState('Available');
  // Property 1
  const [prop1Name, setProp1Name] = useState('');
  const [prop1Value, setProp1Value] = useState('');
  // Property 2
  const [prop2Name, setProp2Name] = useState('');
  const [prop2Value, setProp2Value] = useState('');
  // Property 3
  const [prop3Name, setProp3Name] = useState('');
  const [prop3Value, setProp3Value] = useState('');
  // Property 4
  const [prop4Name, setProp4Name] = useState('');
  const [prop4Value, setProp4Value] = useState('');
  // Property 5
  const [prop5Name, setProp5Name] = useState('');
  const [prop5Value, setProp5Value] = useState('');
  const [productCost, setProductCost] = useState(0);
  const [priceOnSale, setPriceOnSale] = useState(0);
  const [deliveryTime, setDeliveryTime] = useState(1);
  const [newProduct, setNewProduct] = useState(true);
  const [mustHave, setMustHave] = useState(false);
  const [imagesList, setImagesList] = useState([] as Image[]);
  const [availableInZones, setAvailableInZones] = useState([] as TimeZone[]);
  const { productId } = useParams<Params>();
  const [errorMsg, setErrorMsg] = useState('');
  const { editProduct, zones, setEditProduct, setZones } = productSettings();
  const [axiosApiInstance] = useInterceptor();
  useEffect(() => {
    if (isEditProduct) {
      axiosApiInstance.get(`/products/${productId}`).then(
        (res: any) => {
          setEditProduct(res.data);
        },
        () => {
          toast.error('Product can not be loaded');
        }
      );
    }
    //get all available zones
    axiosApiInstance.get(`/settings/available-zones`).then(
      (res: any) => {
        setZones(res.data as TimeZone[]);
      },
      () => {
        toast.error('No available zones');
      }
    );
  }, []);
  useEffect(() => {
    if (isEditProduct && editProduct !== undefined) {
      setId(editProduct.id);
      setProductName(editProduct.name);
      setProductDescription(editProduct.description);
      setAdditionalInfo(editProduct.additionalInfo);
      setAvailableInZones(editProduct.availableInZones);
      const setImageList = [] as Image[];
      editProduct.images.forEach((image: Image, index: number) => {
        image.componentId = index + 1;
        setImageList.push(image);
      });
      setImagesList(setImageList);
      //properties
      const key_arr: string[] = Object.keys(editProduct.properties);
      const val_arr: string[] = Object.values(editProduct.properties);
      if (key_arr[0] && val_arr[0]) {
        setProp1Name(key_arr[0]);
        setProp1Value(val_arr[0]);
      }
      if (key_arr[1] && val_arr[1]) {
        setProp2Name(key_arr[1]);
        setProp2Value(val_arr[1]);
      }
      if (key_arr[2] && val_arr[2]) {
        setProp3Name(key_arr[2]);
        setProp3Value(val_arr[2]);
      }
      if (key_arr[3] && val_arr[3]) {
        setProp4Name(key_arr[3]);
        setProp4Value(val_arr[3]);
      }
      if (key_arr[4] && val_arr[4]) {
        setProp5Name(key_arr[4]);
        setProp5Value(val_arr[4]);
      }
      setProductCost(editProduct.price);
      setPriceOnSale(editProduct.priceOnSale);
      setDeliveryTime(editProduct.deliveryTime);
      setNewProduct(editProduct.newProduct);
      setMustHave(editProduct.mustHaveLabel);
      setProductStatus(editProduct.status);
    }
  }, [editProduct]);
  const saveProduct = async () => {
    let errorMsg = '';
    if (
      productName === '' ||
      productDescription === '' ||
      productStatus === '' ||
      productCost === 0 ||
      deliveryTime.toString() === '' ||
      availableInZones.length === 0
    ) {
      errorMsg = 'Please fill in all required fields!';
      setErrorMsg(errorMsg);
      return;
    }
    let hasImageUpload = false;
    if (imagesList.filter((c: Image) => c.componentId && !c.id).length > 0) {
      const countImageUpload = imagesList.filter((c: Image) => !c.id).length;
      let imageUploaded = 0;
      imagesList.forEach(async (currentImage: Image) => {
        if (currentImage.componentId && !currentImage.id) {
          hasImageUpload = true;
          axios
            .put(currentImage.imageUrl, currentImage.fileObject, {
              headers: {
                'Content-Type': currentImage.fileObject.type,
              },
            })
            .then(
              (res: AxiosResponse) => {
                // Only for the first main image i need await, because for load i need it on the main page
                if (res.status === 200) {
                  imageUploaded += 1;
                  if (countImageUpload === imageUploaded) {
                    toast.success('Product saved successfuly');
                    history.push(ROUTE_PATHS.PRODUCT);
                  } else {
                    return;
                  }
                }
              },
              () => {
                toast.error('Something went wrong');
              }
            );
        }
      });
    }
    const mainImage = imagesList.filter((c: Image) => c.mainImage === true);
    const mainImageUrl = `https://d3nioszw1lcabk.cloudfront.net/${mainImage[0].imageKey}`;
    const productObject = {
      name: productName,
      description: productDescription,
      additionalInfo: additionalInfo,
      properties: {
        [prop1Name]: prop1Value,
        [prop2Name]: prop2Value,
        [prop3Name]: prop3Value,
        [prop4Name]: prop4Value,
        [prop5Name]: prop5Value,
      },
      price: productCost,
      priceOnSale,
      deliveryTime: deliveryTime,
      newProduct: newProduct,
      mustHaveLabel: mustHave,
      images: imagesList,
      availableInZones: availableInZones,
      status: productStatus,
      thumbnail: mainImage[0] ? mainImageUrl : '',
    };

    if (isEditProduct === true) {
      try {
        await axiosApiInstance.put(`/products/${editProduct?.id}`, JSON.stringify(productObject));
      } catch (err) {
        toast.error('Something went wrong');
      }
    } else {
      try {
        await axiosApiInstance.post(`/products/`, JSON.stringify(productObject));
      } catch (err) {
        toast.error('Something went wrong');
      }
    }
    if (!hasImageUpload) {
      toast.success('Product saved successfuly');
      history.push(ROUTE_PATHS.PRODUCT);
    }
  };
  const productAvailableCheck = (item: TimeZone) => {
    let availableProductZoneList = [...availableInZones];
    if (availableProductZoneList.filter(c => c.id === item.id).length > 0) {
      availableProductZoneList = availableProductZoneList.filter(c => c.id !== item.id);
    } else {
      availableProductZoneList.push(item);
    }
    setAvailableInZones(availableProductZoneList);
  };
  const checkAll = () => {
    const allAreSelected = availableInZones.length === zones.filter((c: TimeZone) => c.id !== '0').length;
    if (allAreSelected) {
      setAvailableInZones([]);
    } else {
      setAvailableInZones(zones.filter((c: TimeZone) => c.id !== '0'));
    }
  };
  return (
    <div data-testid="addEditProduct">
      <div className="mt-5">
        <span className="ml-10 mr-10 font-bold text-gray-700">{isEditProduct ? 'Edit Product' : 'Add NEW Product'}</span>
      </div>
      <div className="mb-10 ml-10 mr-10 border-t-2 border-gray-400"></div>
      <div className="ml-10 mr-10 w-6/6">
        <div className="grid gap-1 xl:gap-2 grid-cols-4 xl:grid-cols-6" data-testid="addEdiForm">
          <div className={(isEditProduct === true ? 'visible  ' : 'hidden ') + 'self-center mb-2'}>
            <span>Product ID:</span>
          </div>
          <div
            className={(isEditProduct === true ? 'visible ' : 'hidden ') + 'col-span-3  xl:col-span-5 mb-2'}
            data-testid="productId"
          >
            <span>{id}</span>
          </div>
          <div className="col-span-4 xl:col-span-6">
            <ProductInfo
              productName={productName}
              setProductName={setProductName}
              productDescription={productDescription}
              setProductDescription={setProductDescription}
              additionalInfo={additionalInfo}
              setAdditionalInfo={setAdditionalInfo}
              productStatus={productStatus}
              setProductStatus={setProductStatus}
            />
          </div>
          <div className="col-span-4 xl:col-span-6">
            <ProductProperites
              prop1Name={prop1Name}
              prop1Value={prop1Value}
              setProp1Name={setProp1Name}
              setProp1Value={setProp1Value}
              prop2Name={prop2Name}
              prop2Value={prop2Value}
              setProp2Name={setProp2Name}
              setProp2Value={setProp2Value}
              prop3Name={prop3Name}
              prop3Value={prop3Value}
              setProp3Name={setProp3Name}
              setProp3Value={setProp3Value}
              prop4Name={prop4Name}
              prop4Value={prop4Value}
              setProp4Name={setProp4Name}
              setProp4Value={setProp4Value}
              prop5Name={prop5Name}
              prop5Value={prop5Value}
              setProp5Name={setProp5Name}
              setProp5Value={setProp5Value}
            />
          </div>
          <div className="col-span-4 xl:col-span-6">
            <ProductOrder
              productCost={productCost}
              setProductCost={setProductCost}
              priceOnSale={priceOnSale}
              setPriceOnSale={setPriceOnSale}
              deliveryTime={deliveryTime}
              setDeliveryTime={setDeliveryTime}
              newProduct={newProduct}
              setNewProduct={setNewProduct}
              mustHave={mustHave}
              setMustHave={setMustHave}
            />
          </div>
          <div className="col-span-4 xl:col-span-6">
            <ImageList imagesList={imagesList} setImagesList={setImagesList} />
          </div>
          <div className="w-3/5">
            <span className="text-red text-xl -ml-4">* </span>
            <span>Product will be available in:</span>
          </div>
          <div className="w-3/5 col-span-2 border rounded-sm border-gray-700 mt-2 ">
            <div className="w-full m-2">
              <label className={`checkbox-container inline-block custom-for-zones `}>
                <input
                  data-testid="checkAll"
                  className="mr-2"
                  type="checkbox"
                  checked={availableInZones.length === zones.filter(c => c.id !== '0').length}
                  onChange={() => {
                    checkAll();
                  }}
                />
                <span className="checkmark"></span>
              </label>
              <span className="mr-2">All Offices</span>
              <div className="-ml-1 mr-3 mt-2 border-t border-gray-600" />
            </div>
            {zones
              .filter(c => c.id !== '0')
              .map((item: TimeZone, index: number) => (
                <div key={`z_${item.id}`} className="m-2">
                  <label className={`checkbox-container inline-block custom-for-zones `}>
                    <input
                      data-testid={`check-item-${index}`}
                      className="mr-2"
                      type="checkbox"
                      checked={availableInZones.filter(c => c.id === item.id).length > 0}
                      onChange={() => {
                        productAvailableCheck(item);
                      }}
                    />
                    <span className="checkmark"></span>
                  </label>
                  <span className="mr-2">{item.name}</span>
                </div>
              ))}
          </div>
        </div>
      </div>
      {errorMsg.length > 0 && (
        <div className="flex justify-center mt-10 -mb-8 font-semibold">
          <span data-testid="errorSubmit" className="text-red mx-5">
            {errorMsg}
          </span>
        </div>
      )}
      <div className="h-20">
        <div className="flex justify-center mt-10">
          <button
            data-testid="eventSubmit"
            className={'btn-main'}
            onClick={() => {
              saveProduct();
            }}
          >
            Upload Images & Submit
          </button>
        </div>
      </div>
    </div>
  );
};
export default AddEditProduct;
