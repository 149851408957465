import React, { FunctionComponent, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useInterceptor } from '../../auth/interceptor';
import Image from '../../interfaces/Image';
import ProductCatalogItem from '../../interfaces/product';
import { TimeZone } from '../../interfaces/timezone';
import productSettings from '../../stores/productSettings';
import useSettings from '../../stores/settings';
import { ROUTE_PATHS } from '../../utils/constants/route_paths';
import MainImageDialog from '../products/MainImageDialog';
import DropDown from '../shared/custom_components/DropDown';
import ModalDialog from '../shared/Modal';
import ProductManagementTable from './ProductManagementTable';
//property added to zones array, to display products in all zones when selected
const all_zones: TimeZone = { id: '0', name: 'All Zones', timeZone: '/' };

const ProductManagement: FunctionComponent = () => {
  const history = useHistory();

  const [products, setProducts] = useState([] as ProductCatalogItem[]);
  const [shownProducts, setShownProducts] = useState([] as ProductCatalogItem[]);
  const [showDeleteModal, setShowDeteleteModal] = React.useState(false);
  const [showImageModal, setShowImageModal] = React.useState(false);
  const [deleteItem, setDeleteItem] = useState({} as ProductCatalogItem);
  const [editImageItem, setEditImageItem] = useState({} as ProductCatalogItem);
  const [selectedZone, setSelectedZone] = useState(all_zones);
  const { zones, setZones, setEditProduct } = productSettings();
  const [axiosApiInstance] = useInterceptor();
  const { setSpinner } = useSettings();

  useEffect(() => {
    //get all products in all zones
    axiosApiInstance.get(`/products`).then(
      res => {
        setProducts(res.data.items as ProductCatalogItem[]);
      },
      () => {
        toast.error('No available products');
      }
    );
    //get all available zones
    axiosApiInstance.get(`/settings/available-zones`).then(
      res => {
        setZones([all_zones, ...(res.data as TimeZone[])]);
      },
      () => {
        toast.error('No available zones');
      }
    );
  }, []);

  //every time array of all products change, filter shown products by selected zone
  useEffect(() => {
    filterProductList();
  }, [products]);

  //every time selected zone change, filter shown products by selected zone
  useEffect(() => {
    setSpinner(true);
    const timer: ReturnType<typeof setTimeout> = setTimeout(() => {
      setSpinner(false);
      clearTimeout(timer);
    }, 500);
    filterProductList();
    return function cleanup() {
      clearTimeout(timer);
    };
  }, [selectedZone]);
  //test
  useEffect(() => {}, [shownProducts]);

  //filter products that are shown on screen, by selected zone
  const filterProductList = () => {
    if (products) {
      setShownProducts(
        products.filter(prod => {
          return prod.availableInZones.find(z => z.id === selectedZone.id) || selectedZone.id === '0';
        })
      );
    }
  };

  //set selected zone and filter shown products according to the selected zone,
  // when zone is changed in the dropdown
  const selectZone = (zone: TimeZone) => {
    setSelectedZone(zone);
  };

  //open popover where additional images can be added
  const onAdditionalImagesClick = (item: ProductCatalogItem) => {
    setEditImageItem(item);
    setShowImageModal(true);
  };

  //edit existing product from the list of products
  const onEditClick = (item: ProductCatalogItem) => {
    setEditProduct(item);
    setShowImageModal(false);
    history.push(`${ROUTE_PATHS.EDITPRODUCT}/${item.id}`);
  };

  //view product from the list of products
  const onViewClick = (item: ProductCatalogItem) => {
    history.push(`${ROUTE_PATHS.VIEWPRODUCT}/${item.id}`);
  };

  //delete existing product from the list of products
  const onDeleteClick = () => {
    if (deleteItem) {
      axiosApiInstance.delete(`/products/${deleteItem.id}`).then(
        () => {
          setProducts(products.filter(prod => prod.id !== deleteItem.id));
        },
        () => {}
      );
    }
  };

  const onSaveImageList = async (hasChanges: boolean, updatedImageList: Image[]) => {
    if (hasChanges === true) {
      const updateItem = editImageItem;
      editImageItem.images = updatedImageList;
      const mainImage = updatedImageList.filter(c => c.mainImage === true);
      editImageItem.thumbnail = `https://d3nioszw1lcabk.cloudfront.net/${mainImage[0].imageKey}`;
      await axiosApiInstance.put(`/products/${updateItem?.id}`, JSON.stringify(updateItem));

      const updateProductList = [...products];
      updateProductList.map((currentProduct: ProductCatalogItem) => {
        if (currentProduct.id === editImageItem.id) {
          currentProduct.images = updatedImageList;
        }
      });

      setProducts(updateProductList);
    }
  };

  //open popover for adding new product in the list of products
  const addNewProduct = () => {
    history.push(ROUTE_PATHS.ADDPRODUCT);
  };

  return (
    <div className="h-screen w-screen bg-white place-items-center px-10 py-12" data-testid="productManagement">
      {/* add new product section */}
      <div className="flex justify-start mb-3">
        <a href="#" data-testid="newProduct" onClick={() => addNewProduct()} className={'btn-main'}>
          Add a New Product
        </a>
      </div>
      <hr className="mb-6" />
      {/* select zone section */}
      <div className="mb-3">
        <span className="mr-6 mb-3">The table below shows the product catalog for: </span>
        <div className="inline-block fixed-width" data-testid="zone-dropdown">
          <DropDown
            value={selectedZone.name}
            placeholder="Select zone"
            onChange={(v: string) => {
              const szone = zones.find(z => z.name === v);
              if (szone) {
                selectZone(szone);
              }
            }}
            options={zones.map(zone => zone.name)}
          />
        </div>
      </div>
      {/* list of products in table section */}
      <ProductManagementTable
        zones={zones}
        shownProducts={shownProducts}
        onAdditionalImagesClick={onAdditionalImagesClick}
        setShowDeteleteModal={setShowDeteleteModal}
        setDeleteItem={setDeleteItem}
        onViewClick={onViewClick}
        onEditClick={onEditClick}
      />
      {showDeleteModal && (
        <ModalDialog
          modalText={`Are you sure you wish to delete ${deleteItem.name} from the Product’s Catalog?`}
          modalTitle={'DELETE PRODUCT'}
          closeShowModal={() => {
            setShowDeteleteModal(false);
          }}
          confirmAction={onDeleteClick}
          confirmBtnText={'Delete'}
          showCancel={true}
        />
      )}
      {showImageModal && (
        <MainImageDialog
          modalImageList={editImageItem.images}
          modalTitle={`${editImageItem.name}'s Image`}
          closeShowModal={() => {
            setShowImageModal(false);
          }}
          confirmAction={onSaveImageList}
          confirmBtnText={'Delete'}
          editImages={() => onEditClick(editImageItem)}
        />
      )}
    </div>
  );
};

export default ProductManagement;
