import { AxiosResponse } from 'axios';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useInterceptor } from '../../auth/interceptor';
import OrderItem, { OrderItemShown } from '../../interfaces/Order';
import OrderStatus from '../../interfaces/OrderStatus';
import { TimeZone } from '../../interfaces/timezone';
import useSettings from '../../stores/settings';
import DropDown from '../shared/custom_components/DropDown';
import ModalDialog from '../shared/Modal';
import OrdersManagementTable from './OrdersManagementTable';

const OrdersManagement: FunctionComponent = () => {
  const [orders, setOrders] = useState([] as OrderItemShown[]);
  const [shownOrders, setShownOrders] = useState([] as OrderItemShown[]);
  const [axiosApiInstance] = useInterceptor();
  const [selectedZone, setSelectedZone] = useState({ id: '0', name: 'All zones' } as TimeZone);
  const [zones, setZones] = useState([] as TimeZone[]);
  const [allSelected, setAllSelected] = useState(false);
  const [orderStatuses, setOrderStatuses] = useState([] as OrderStatus[]);
  const [changeStatuses, setChangeStatuses] = useState([] as OrderStatus[]);
  const [selectedStatus, setSelectedStatus] = useState({ id: '0', name: 'All statuses' } as OrderStatus);
  const [selectedChangeStatus, setSelectedChangeStatus] = useState({ id: '0', name: '' } as OrderStatus);
  const [showOrderStatusModal, setOrderStatusModal] = React.useState(false);
  const [updatedOrderMsg, setUpdatedOrderMsg] = useState([] as JSX.Element[]);
  const [reports, setReports] = useState('');
  const { setSpinner } = useSettings();

  useEffect(() => {
    setSelectedStatus({ id: '0', name: 'All statuses' });
    //get all available zones
    axiosApiInstance.get(`/settings/available-zones`).then(
      (res: AxiosResponse) => {
        setZones(res.data as TimeZone[]);
      },
      () => {
        toast.error('No available zones');
      }
    );
    //get all order statuses
    axiosApiInstance.get(`/orders/order-statuses`).then(
      res => {
        setOrderStatuses([selectedStatus, ...(res.data as OrderStatus[])]);
        setChangeStatuses(res.data as OrderStatus[]);
      },
      () => {
        toast.error('No available order statuses');
      }
    );
  }, []);

  //set selected zone when statuses are received
  useEffect(() => {
    if (selectedZone.id === '0' && zones.length > 0) {
      setSelectedZone(zones[0]);
    }
  }, [zones]);

  //every time selected zone change, filter shown orders by selected zone
  useEffect(() => {
    setSpinner(true);
    getOrdersForZone();
  }, [selectedZone]);

  //every time selected status change, filter shown orders by selected zone
  useEffect(() => {
    setAllSelected(false);
    setOrders(
      orders.map(order => ({
        ...order,
        checked: false,
      }))
    );
    switch (selectedStatus.order) {
      case 1:
        setChangeStatuses(orderStatuses.filter(status => status.order === 2 || status.order === 3 || status.order === 4));
        break;
      case 2:
        setChangeStatuses(orderStatuses.filter(status => status.order === 3 || status.order === 4));
        break;
      case 3:
        setChangeStatuses([]);
        break;
      case 4:
        setChangeStatuses([]);
        break;
      default:
        setChangeStatuses(orderStatuses);
    }
    setSelectedChangeStatus({ id: '0', name: '' });
  }, [selectedStatus]);

  //every time selected status change, filter shown orders by selected zone
  useEffect(() => {
    filterProductList();
    downloadReports();
  }, [orders]);

  //when users list change check if all users are checked or not
  useEffect(() => {
    if (shownOrders.length) {
      const all = shownOrders.find(el => !el.checked) ? false : true;
      setAllSelected(all);
    }
  }, [shownOrders]);

  //filter orders that are shown on screen, by selected zone and selected status
  const getOrdersForZone = () => {
    if (selectedZone.id) {
      //get purchases for selected zone
      axiosApiInstance.get(`/orders?zoneId=${selectedZone.id}`).then(
        res => {
          setOrders(
            res.data.items.map((item: OrderItem) => {
              return { ...item, checked: false } as OrderItemShown;
            })
          );
          setSpinner(false);
        },
        () => {
          setSpinner(false);
          setOrders([]);
        }
      );
    }
  };

  //filter orders that are shown on screen, by selected zone and selected status
  const filterProductList = () => {
    if (orders) {
      setShownOrders(
        orders.filter(
          order =>
            (order.zoneId === selectedZone.id || selectedZone.id === '0') &&
            ((order.status && order.status.id === selectedStatus.id) || selectedStatus.id === '0')
        )
      );
    }
  };

  //set selected zone and filter shown orders according to the selected zone,
  // when zone is changed in the dropdown
  const selectZone = (zone: TimeZone | undefined) => {
    if (zone) {
      setSelectedZone(zone);
    }
  };

  //set selected zone and filter shown orders according to the selected zone,
  // when zone is changed in the dropdown
  const selectStatus = (status: OrderStatus | undefined) => {
    if (status) {
      setSpinner(true);
      const timer: ReturnType<typeof setTimeout> = setTimeout(() => {
        setSpinner(false);
        clearTimeout(timer);
      }, 500);
      setSelectedStatus(status);
    }
  };

  //set selected zone and filter shown orders according to the selected zone,
  // when zone is changed in the dropdown
  const selectChangeStatus = (status: OrderStatus | undefined) => {
    if (status) {
      setSelectedChangeStatus(status);
    }
  };

  //toggle 'select all' purchase orders
  const onSelectAllChange = () => {
    setShownOrders(
      shownOrders.map((_item: OrderItemShown) => {
        _item.checked = !allSelected;
        return _item;
      })
    );
    setAllSelected(!allSelected);
  };

  //toggle one purchase orders
  const selectItem = (item: OrderItemShown) => {
    setShownOrders(
      shownOrders.map((_item: OrderItemShown) => {
        if (_item.id === item.id) {
          _item.checked = !_item.checked;
        }
        return _item;
      })
    );
  };

  // download report with all orders with status different than "Cancelled" or "Delivered"
  const downloadReports = () => {
    if (
      orders &&
      orders.length &&
      orders.find(order => order.status.id === '1' || order.status.id === '2' || order.status.id === '3')
    ) {
      setSpinner(true);
      axiosApiInstance.get(`/orders/orders-not-completed-excel/${selectedZone.id}`).then(
        res => {
          if (res.data && res.data.excelFileUrl !== 'string') {
            setReports(res.data.excelFileUrl);
          }
          setSpinner(false);
        },
        () => {
          setSpinner(false);
          toast.error('No report found');
        }
      );
    } else {
      setReports('');
    }
  };

  const onSubmit = () => {
    const selectedOrders = shownOrders.filter(order => order.checked === true);
    const obj = {
      orders: selectedOrders,
      newStatus: selectedChangeStatus,
      currentStatus: selectedStatus,
    };
    axiosApiInstance.put(`/orders/orders-statuses`, obj).then(
      res => {
        const msg: JSX.Element[] = [] as JSX.Element[];
        if (res.data.updatedOrderIds) {
          msg.push(
            <div key="0" className="mb_2">
              Status updated successfully for orders with following products:
            </div>
          );
          res.data.updatedOrderIds.forEach((element: string, index: number) => {
            const order = orders.find(o => o.id === element);
            if (order) {
              msg.push(
                <div className="my-3" key={`msg_product_${index}`}>
                  <strong className="font-bold">{` ${order.product.name}`}</strong>, order ID{' '}
                  <strong className="font-bold">{order.id}</strong>
                </div>
              );
            }
          });
        }
        if (res.data.notUpdatedOrderIds) {
          msg.push(<div key="0">Status update failed for orders with folowing products:</div>);
          res.data.notUpdatedOrderIds.forEach((element: string, index: number) => {
            const order = orders.find(o => o.id === element);
            if (order) {
              msg.push(
                <div className="my-3" key={`msg_product_${index}`}>
                  <strong className="font-bold">{` ${order.product.name}`}</strong>, order ID{' '}
                  <strong className="font-bold">{order.id}</strong>
                </div>
              );
            }
          });
        }
        setUpdatedOrderMsg(msg);
        if (res.data.excelFileUrl) {
          setReports(res.data.excelFileUrl);
        }
        onSelectAllChange();
        getOrdersForZone();

        setOrderStatusModal(true);
        setSelectedChangeStatus({ id: '0', name: '' });
      },
      () => {
        toast.error('Something went wrong');
      }
    );
  };

  return (
    <div className="h-screen w-screen bg-white place-items-center px-10 py-12" data-testid="ordersManagement">
      {/* select zone section */}
      <div className="mb-6 grid gap-x-4 grid-cols-4 lg:grid-cols-4 items-center">
        <span>The table below shows purchase orders for: </span>
        <div className="inline-block" data-testid="zone-dropdown">
          <DropDown
            value={selectedZone.name}
            placeholder="Select zone"
            onChange={(v: string) => {
              selectZone(zones.find(z => z.name === v));
            }}
            options={zones.map(zone => zone.name)}
          />
        </div>
      </div>

      {/* select status section*/}
      <div className="mb-10 grid gap-x-4 grid-cols-4 lg:grid-cols-4 items-center">
        <span>Show purchase orders in status: </span>
        <div className="inline-block" data-testid="status-dropdown">
          <DropDown
            value={selectedStatus.name}
            placeholder="Select status"
            onChange={(v: string) => {
              selectStatus(orderStatuses.find(s => s.name === v));
            }}
            options={orderStatuses.map(s => s.name)}
          />
        </div>
      </div>
      <span className={`flex items-center justify-end text-center mb-3 absolute right-0 mr-10 -mt-20`}>
        <a href={reports} data-testid="excel_download" className={`btn-main ${reports === '' ? ' disabled' : ''}`}>
          Download Purchases Report
        </a>
      </span>
      {/* orders management table */}
      <OrdersManagementTable
        allSelected={allSelected}
        onSelectAllChange={onSelectAllChange}
        selectedStatus={selectedStatus}
        shownOrders={shownOrders}
        selectItem={selectItem}
      />

      {/* select status section*/}
      <div className="mb-3 grid gap-x-4 grid-cols-4 lg:grid-cols-4 items-center">
        <span className="mr-1">Set status for checked orders to: </span>
        <div className="inline-block" data-testid="changeStatus-dropdown">
          <DropDown
            value={selectedChangeStatus === undefined ? '' : selectedChangeStatus.name}
            placeholder="Select status"
            onChange={(v: string) => {
              selectChangeStatus(changeStatuses.find(s => s.name === v));
            }}
            options={changeStatuses.map(s => s.name)}
            disabled={selectedStatus.id === '0' || changeStatuses.length === 0}
          />
        </div>
        <span className="flex items-center justify-start">
          <a
            href="#"
            data-testid="om_submit"
            onClick={() => {
              if (selectedStatus.id !== '0' && shownOrders.filter(order => order.checked === true).length > 0) {
                onSubmit();
              }
            }}
            className={`btn-main  ${
              selectedStatus.id === '0' ||
              selectedChangeStatus.id === '0' ||
              shownOrders.filter(order => order.checked === true).length === 0 ||
              selectedChangeStatus.id === selectedStatus.id
                ? ' disabled'
                : ''
            }`}
          >
            Submit
          </a>
        </span>
      </div>
      {showOrderStatusModal && (
        <ModalDialog
          modalJSX={updatedOrderMsg}
          modalTitle={'ORDERS STATUS UPDATE RESULTS'}
          closeShowModal={() => {
            setOrderStatusModal(false);
          }}
          confirmAction={() => {
            setOrderStatusModal(false);
          }}
          confirmBtnText={'OK'}
          showCancel={false}
        />
      )}
    </div>
  );
};

export default OrdersManagement;
