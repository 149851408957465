import { AxiosResponse } from 'axios';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useInterceptor } from '../../auth/interceptor';
import NotificationItem from '../../interfaces/notification';
import { TimeZone } from '../../interfaces/timezone';
import Unit from '../../interfaces/Unit';
import useSettings from '../../stores/settings';
// helper function
import { validateEmail } from '../../utils/validations';
// Components
import DropDown from '../shared/custom_components/DropDown';
import ModalDialog from '../shared/Modal';
import NotificationsCenterTable from './NotificationsCenterTable';

//property added to zones array, to display products in all zones when selected
const all_zones: TimeZone = { id: '0', name: 'All Zones', timeZone: '/' };

const NotificationsCenter: React.FunctionComponent = () => {
  // Create new notification
  const [selectedZone, setSelectedZone] = useState(all_zones as TimeZone);
  const [zones, setZones] = useState([all_zones] as TimeZone[]);
  const [axiosApiInstance] = useInterceptor();
  const [apiGatewayApiInstance] = useInterceptor(true);
  const [selectedUnit, setSelectedUnit] = useState({ id: '0', name: 'All studios/units', description: '' } as Unit);
  const [units, setUnits] = useState([] as Unit[]);
  const [allSelected, setAllSelected] = useState(false);
  const [locationNotification, setLocationNotification] = useState(true);
  const [notificationEmailTo, setNotificationEmailTo] = useState('');
  // Validation error messages
  const [errEmailNotification, setErrEmailNotification] = useState(true);
  const [notificationTitle, setNotificationTitle] = useState('');
  const [notificationBody, setNotificationBody] = useState('');
  // Notification Msg List
  const [notifications, setNotifications] = useState([] as NotificationItem[]);
  const [deleteItem, setDeleteItem] = useState({} as NotificationItem);
  const [viewItem, setViewItem] = useState({} as NotificationItem);
  const { setSpinner } = useSettings();
  // Modal Popups
  const [showViewModal, setShowViewModal] = React.useState(false);
  const [showDeleteModal, setShowDeteleteModal] = React.useState(false);
  const [notificationSentModal, setNotificationSentModal] = React.useState(false);

  const loadNotifications = () => {
    apiGatewayApiInstance
      .get(`/notifications/status`, {
        params: {
          status: 'Pending',
        },
      })
      .then(
        res => {
          setNotifications(res.data as NotificationItem[]);
        },
        () => {
          toast.error('No notifications found');
        }
      );
  };
  useEffect(() => {
    //get all available zones
    axiosApiInstance.get(`/settings/available-zones`).then(
      res => {
        setZones([all_zones, ...(res.data as TimeZone[])]);
      },
      () => {
        toast.error('No zones available');
      }
    );
    //get all available studios/units
    axiosApiInstance.get(`/settings/studios-units`).then(
      (res: AxiosResponse) => {
        setUnits([selectedUnit, ...(res.data as Unit[])]);
      },
      () => {
        toast.error('No studio units available');
      }
    );
    // get all notifications
    setSpinner(true);
    const timer: ReturnType<typeof setTimeout> = setTimeout(() => {
      setSpinner(false);
      clearTimeout(timer);
    }, 500);
    loadNotifications();
  }, []);
  //set selected zone
  // when zone is changed in the dropdown
  const selectZone = (zone: TimeZone | undefined) => {
    if (zone) {
      setSelectedZone(zone);
    }
  };
  //set selected unit
  // when unit is changed in the dropdown
  const selectUnit = (unit: Unit | undefined) => {
    if (unit) {
      setSelectedUnit(unit);
    }
  };
  const createNotification = async () => {
    if (locationNotification === false && (notificationEmailTo.length === 0 || errEmailNotification === false)) {
      toast.error('Please check your Playtikan email field');
    } else {
      if (notificationBody.length > 0 && notificationTitle.length > 0 && errEmailNotification === true) {
        const notificationObject = {
          zoneName: locationNotification ? selectedZone.name : '',
          studioUnitName: locationNotification ? selectedUnit.name : '',
          userEmail: notificationEmailTo,
          body: notificationBody,
          title: notificationTitle,
          type: 'all',
        };
        apiGatewayApiInstance.post(`/notifications`, JSON.stringify(notificationObject)).then(
          () => {
            loadNotifications();
            // Init data form
            setSelectedZone(all_zones as TimeZone);
            setSelectedUnit({ id: '0', name: 'All studios/units', description: '' } as Unit);
            setLocationNotification(true);
            setNotificationEmailTo('');
            setNotificationBody('');
            setNotificationTitle('');
            toast.success('Notification was created successfully');
          },
          () => {
            toast.error('Something went wrong');
          }
        );
      } else {
        toast.error('Notification message should NOT be empty');
      }
    }
  };
  //toggle 'select all' purchase orders
  const onSelectAllChange = () => {
    setNotifications(
      notifications.map((_item: NotificationItem) => {
        if (_item.status !== 'Sent') {
          _item.checked = !allSelected;
        }
        return _item;
      })
    );
    setAllSelected(!allSelected);
  };
  //toggle one purchase orders
  const selectItem = (item: NotificationItem) => {
    setNotifications(
      notifications.map((_item: NotificationItem) => {
        if (_item.id === item.id) {
          if (allSelected === true && _item.checked === true) {
            setAllSelected(false);
          }
          _item.checked = !_item.checked;
        }
        return _item;
      })
    );
    const pendingNotifications = notifications.filter(c => c.status === 'Pending');
    if (pendingNotifications.filter(c => c.checked === true).length === pendingNotifications.length) {
      setAllSelected(true);
    }
  };
  const onDeleteItemClick = (item: NotificationItem) => {
    setDeleteItem(item);
    setShowDeteleteModal(true);
  };
  const onViewClick = (item: NotificationItem) => {
    setViewItem(item);
    setShowViewModal(true);
  };
  //delete existing notification from the list of products
  const onDeleteClick = () => {
    if (deleteItem) {
      apiGatewayApiInstance.delete(`/notifications/${deleteItem.id}`).then(
        () => {
          setNotifications(notifications.filter(notification => notification.id !== deleteItem.id));
        },
        () => {
          toast.error('Something went wrong');
        }
      );
    }
  };
  const onSendMsgs = async () => {
    const selectedMsgs = notifications.filter(c => c.checked === true);
    if (selectedMsgs.length > 0) {
      const ids = {
        ids: selectedMsgs.map(obj => obj.id),
      };
      apiGatewayApiInstance.post(`/notifications/send`, JSON.stringify(ids)).then(
        () => {
          loadNotifications();
          setNotificationSentModal(true);
        },
        () => {
          toast.error('Something went wrong');
        }
      );
    } else {
      toast.error('Please first select message to send!');
    }
  };
  return (
    <div className="h-screen w-screen bg-white place-items-center px-10 py-12" data-testid="notificationCenter">
      <div className="section-step mb-6">
        <p className="mb-3 align-middle font-bold">STEP 1 - CREATE NOTIFICATION</p>
        <div className="px-3 grid gap-x-1 grid-cols-3 xl:grid-cols-4 items-center">
          <div className="mb-6 col-span-3 xl:col-span-4 -ml-4">
            Create notification to Playtikans, based on office-location, studio, or a personal message.
          </div>
          <div className="mb-6">
            <label className={`checkbox-container`}>
              {' '}
              <input
                type="checkbox"
                data-testid="chk_locationNotification"
                checked={locationNotification === true}
                onChange={() => {
                  setLocationNotification(!locationNotification);
                }}
              />
              <span className="rounded-checkmark"></span>
            </label>
            <span className="pl-6 ml-2">Group notifications, based on office location:</span>
          </div>
          <div className="col-span-2 items-center flex mb-6">
            <div className="col-span-2 flex items-center mb-6 dropdown-minwidth" data-testid="zone-dropdown">
              <DropDown
                value={selectedZone.name}
                placeholder="Select zone"
                onChange={(v: string) => {
                  selectZone(zones.find(z => z.name === v));
                }}
                disabled={!locationNotification}
                options={zones.map(zone => zone.name)}
              />
            </div>
            <span className="px-3 flex mb-6">and Studio/Unit</span>
            <div className="col-span-2 flex items-center mb-6 dropdown-minwidth" data-testid="studio-dropdown">
              <DropDown
                value={selectedUnit.name}
                placeholder="Select studio/unit"
                onChange={(v: string) => {
                  selectUnit(units.find(u => u.name === v));
                }}
                disabled={!locationNotification}
                options={units.map(unit => unit.name)}
              />
            </div>
          </div>
          <div className="hidden xl:inline xl:col-span-1"></div>
          <div className="mb-10">
            <label className={`checkbox-container`}>
              {' '}
              <input
                type="checkbox"
                data-testid="selectone"
                checked={locationNotification === false}
                onChange={() => {
                  setLocationNotification(!locationNotification);
                }}
              />
              <span className="rounded-checkmark"></span>
            </label>
            <span className="pl-6 ml-2">Personal information, based on Playtikan email:</span>
          </div>
          <input
            className="col-span-2 input-text mb-6"
            data-testid="notificationEmailTo"
            disabled={locationNotification}
            value={notificationEmailTo}
            onChange={e => setNotificationEmailTo(e.target.value)}
            onBlur={e => {
              setLocationNotification(false);
              setErrEmailNotification(validateEmail(e.target.value));
            }}
          />
          {!errEmailNotification && notificationEmailTo.length > 0 && (
            <span data-testid="errEmailNotification" className="text-red text-sm md:mt-1 mt-28 md:w-52">
              Please enter valid email!
            </span>
          )}
          <div className="hidden xl:inline xl:col-span-1"></div>
          <div className="mb-6 col-span-3 xl:col-span-4 -ml-4">Type of notification:</div>
          <div className="mb-6 col-span-3 xl:col-span-4">
            <label className={`checkbox-container`}>
              {' '}
              <input type="checkbox" data-testid="pushNotification" checked={true} />
              <span className="rounded-checkmark"></span>
            </label>
            <span className="pl-6 ml-2">Push notification (and in the Notification Center)</span>
          </div>
          <span className="flex items-center col-span-1 pr-3 -ml-4">Notification title:</span>
          <input
            className="input-text items-center col-span-2 mt-4"
            value={notificationTitle}
            data-testid="notificationTitle"
            onChange={e => {
              if (e.target.value.length < 201) {
                setNotificationTitle(e.target.value);
              }
            }}
          />
          <span className="flex items-center col-span-1 pr-3 -ml-4">Notification message:</span>
          <textarea
            className="input-text items-center col-span-2 mt-9"
            value={notificationBody}
            data-testid="notificationBody"
            onChange={e => {
              if (e.target.value.length < 201) {
                setNotificationBody(e.target.value);
              }
            }}
          ></textarea>
          <div className="hidden xl:inline xl:col-span-1"></div>
          <div className="inline-block col-span-3">
            <span className="flex items-center justify-end text-center mt-1">
              ({notificationBody !== null ? notificationBody.length : 0}/200 characters)
            </span>
          </div>
          <div className="hidden xl:inline xl:col-span-1"></div>
          <div className="col-span-1"></div>
          <div className="inline-block col-span-2">
            <span className="flex items-center justify-end text-center my-3">
              <a data-testid="go_give_btn" className={`btn-main pl-10 pr-10`} onClick={createNotification}>
                CREATE NOTIFICATION IN PENDING STATUS
              </a>
            </span>
          </div>
          <div className="hidden xl:inline xl:col-span-1"></div>
        </div>
      </div>
      <div className="section-step">
        <p className="mb-3 align-middle font-bold">STEP 2 - SEND NOTIFICATION</p>
        {/* table of notifications */}
        <NotificationsCenterTable
          allSelected={allSelected}
          notificationList={notifications}
          onSelectItem={selectItem}
          onSelectAllChange={onSelectAllChange}
          onViewClick={onViewClick}
          onDeleteItemClick={onDeleteItemClick}
        />
        {showDeleteModal && (
          <ModalDialog
            modalText={`Are you sure you wish to delete the message?`}
            additionalText={`Deleting it will remove the message from the list but will not remove it from repients' notifications center`}
            modalTitle={'DELETE NOTIFICATION'}
            closeShowModal={() => {
              setShowDeteleteModal(false);
            }}
            confirmAction={onDeleteClick}
            confirmBtnText={'Delete'}
            showCancel={true}
          />
        )}
        {showViewModal && (
          <ModalDialog
            modalText={viewItem.body}
            modalTitle={'NOTIFICATION MESSAGE'}
            closeShowModal={() => {
              setShowViewModal(false);
            }}
            confirmAction={() => {
              setShowViewModal(false);
            }}
            confirmBtnText={'OK'}
            isNotification={true}
            showCancel={false}
          />
        )}
        {notificationSentModal && (
          <ModalDialog
            modalText={'Notification  messages are successfully sent!'}
            modalTitle={'SENT NOTIFICATIONS'}
            closeShowModal={() => {
              setNotificationSentModal(false);
            }}
            confirmAction={() => {
              setNotificationSentModal(false);
            }}
            confirmBtnText={'OK'}
            showCancel={false}
          />
        )}
        <span className="flex items-center justify-end text-center my-3 px-3">
          <a
            data-testid="go_give_btn"
            className={`btn-main pl-10 pr-10 mb-16 ${
              notifications.filter(c => c.checked === true).length > 0 ? '' : ' disabled'
            }`}
            onClick={onSendMsgs}
          >
            SEND
          </a>
        </span>
      </div>
    </div>
  );
};
export default NotificationsCenter;
