/**
 * Copyrights 2021 Playtika Ltd., all rights reserved to Playtika Ltd.
 * privacy+e17bb14d-edc1-4d26-930d-486fcc1ab8fe@playtika.com
 */

export const ROUTE_PATHS = {
  HOME: '/',
  SYSTEMSETTINGS: '/systemSettings',
  LOGIN: '/login',
  PRODUCT: '/product',
  ADDPRODUCT: '/product/addNewProduct',
  EDITPRODUCT: '/product/editProduct',
  VIEWPRODUCT: '/product/viewProduct',
  ORDERS: '/orders',
  PLAYTICOINS: '/playtiCoins',
  NOTIFICATIONS: '/notifications',
  LOCATION: '/location',
  CMS: '/cms',
  ADDCATEGORY: '/cms/addNewCategory',
  ADDSUBCATEGORYORCONTENT: '/cms/addSubCategoryOrContent',
  EDITCATEGORY: '/cms/editCategory',
  VIEWPAGECONTENT: '/cms/viewPageContent',
};
