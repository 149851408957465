import React, { FunctionComponent } from 'react';
//components
import DropDown from '../../shared/custom_components/DropDown';

export type ProductProps = {
    productName: string;
    setProductName: (v: string) => void;
    productDescription: string;
    setProductDescription: (v: string) => void;
    additionalInfo: string;
    setAdditionalInfo: (v: string) => void;
    productStatus: string;
    setProductStatus: (v: string) => void;
};

const ProductInfo: FunctionComponent<ProductProps> = (props: ProductProps) => {
  return (
    <div className="grid gap-1 xl:gap-2 grid-cols-4 xl:grid-cols-6" data-testid="productInfo">
         <div className="self-center">
            <span className="text-red text-xl -ml-4">* </span>
            <span>Product Name:</span>
          </div>
          <div className="col-span-2  xl:col-span-3">
            <input
              data-testid="productName"
              className="input-text mb-3"
              value={props.productName}
              onChange={e => {
                if (e.target.value.length <= 100) {
                    props.setProductName(e.target.value);
                }
              }}
            />
          </div>
          <div className="justify-self-start mt-4 xl:col-span-2">{props.productName.length}/100</div>
          <div className="self-center">
            <span className="text-red text-xl -ml-4">* </span>
            Product Description:
          </div>
          <div className="col-span-2  xl:col-span-3">
            <textarea
              rows={3}
              data-testid="productDesc"
              className="input-text mb-5 "
              value={props.productDescription}
              onChange={e => {
                if (e.target.value.length < 500) {
                    props.setProductDescription(e.target.value);
                }
              }}
            />
          </div>
          <div className="justify-self-start mt-4 xl:col-span-2">{props.productDescription.length}/500</div>
          <div className="self-center">Additional info:</div>
          <div className="col-span-2  xl:col-span-3">
            <textarea
              rows={3}
              data-testid="additionalInfo"
              className="input-text mb-5"
              value={props.additionalInfo}
              onChange={e => {
                if (e.target.value.length < 500) {
                    props.setAdditionalInfo(e.target.value);
                }
              }}
            />
          </div>
          <div className="justify-self-start mt-4 xl:col-span-2">
              {props.additionalInfo !== null ? props.additionalInfo.length : 0}/500
          </div>
          <div className="self-center">
            <span className="text-red text-xl -ml-4">* </span>
            <span>Status:</span>
          </div>
          <div className="xl:col-span-2">
            <div className="dropdown w-full" data-testid="status">
              <DropDown
                value={props.productStatus}
                onChange={(v: string) => {
                    props.setProductStatus(v);
                }}
                options={['Available', 'Unavailable']}
              />
            </div>
          </div>
          <div></div>
          <div className="justify-self-start  mt-4  xl:col-span-2"></div>
    </div>
  );
};
export default ProductInfo;
