import React, { FunctionComponent, useEffect, useState } from 'react';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import { useInterceptor } from '../../auth/interceptor';
import User, { UserItem } from '../../interfaces/user';
import GiftCard from '../../interfaces/gift-card';

import ModalDialog from '../shared/Modal';
import TableColProperty from '../shared/interfaces/TableColProperty';
import Table from '../shared/Table';

import deleteIcon from '../../assets/images/icon_decline.png';

interface Props {
  user: User;
  usersList: UserItem[];
  closeShowModal: () => void;
}

const UBH_COLUMN_PROPERTIES: TableColProperty[] = [
    { title: 'Gift ID', colMaxWidth: 'auto', colMinWidth: 'auto', apiValue: 'id' },
    { title: 'Gift Type', colMaxWidth: 'auto', colMinWidth: 'auto', apiValue: 'typeId' },
    { title: 'Gift Origin', colMaxWidth: 'auto', colMinWidth: 'auto', apiValue: 'giftReceiverId' },
    { title: 'Gift Amount', colMaxWidth: 'auto', colMinWidth: 'auto', apiValue: 'amount' },
    { title: 'Decline Gift', colMaxWidth: 'auto', colMinWidth: 'auto', apiValue: 'balance' }
  ];
const PendingGiftsModal: FunctionComponent<Props> = ({ user, usersList,  closeShowModal }) => {
  const [axiosApiInstance] = useInterceptor();
  const [giftCardsList, setGiftCardsList] = useState([] as GiftCard[]);
  const [removeItem, setRemoveItem] = useState({} as GiftCard);
  const [showRemoveGiftModal, setShowRemoveGiftModal] = React.useState(false);
  useEffect(() => {
    const params = {
        userId: user.id,
        status: 'pending'
      };
    //get all pending gifts
    axiosApiInstance.get(`/gifts/gift-cards-filtered`, { params } as AxiosRequestConfig).then(
      (res: AxiosResponse) => {
        setGiftCardsList(res.data as GiftCard[]);
      },
      () => {
        toast.error('No data available');
      }
    );
  }, []);

  const cancelPendingGifts = (item: GiftCard) => {
    const data = {
        id: item.id,
        status: 'canceled'
      };
    //update pending gift status
    axiosApiInstance.put(`/gifts/gift-card-status/${item.id}`, data).then(
      () => {
        const updateGiftList = giftCardsList.filter(c=> c.id !== item.id);
        setGiftCardsList(updateGiftList);
      },
      () => {
        toast.error('Update failed');
      }
    );
  };
  const declineGiftClick = (item: GiftCard) => {
    setRemoveItem(item);
    setShowRemoveGiftModal(true);
  };
  return (
    <>
      <div
        data-testid="modalPendingGifts"
        className="justify-center items-center flex overflow-x-hidden fixed inset-0 z-50 outline-none focus:outline-none"
      >
        <div className="relative w-4/6 h-5/6 my-4 mx-auto max-w-2xl">
          {/*content*/}
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            {/*header*/}
            <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
              <h3 className="text-xl font-semibold">{user.firstName + ' ' + user.lastName}</h3>
              <button
                data-testid="close-button"
                className="p-1 z-50 ml-auto rounded-lg -mt-2 bg-transparent border text-black opacity-3 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                onClick={() => closeShowModal()}
              >
                <span className="bg-transparent -mt-1 text-black px-1 h-7 w-6 text-2xl block outline-none focus:outline-none">
                  ×
                </span>
              </button>
            </div>
            {/*body*/}
            <div className="relative p-6 flex-auto">
              <p className="my-4 text-blueGray-500 text-lg font-semibold leading-relaxed">
                Employee email: {user.email} 
              </p>
              <p className="my-4 text-blueGray-500 text-lg font-semibold leading-relaxed">
                Office: {user.zone ? user.zone.name : ''}
              </p>
              <p className="my-4 text-blueGray-500 text-lg font-semibold leading-relaxed">
                Current balance: {user.currentBalance}
              </p>
            </div>
            <div className="w-4/6 ml-20">
            <div className="table-holder mb-6">
        <Table
          columnProps={UBH_COLUMN_PROPERTIES}
        >
          {giftCardsList.map((item, index: number) => (
            <tr key={`pm_row_${index}`} data-testid="playticoin-row">
              {UBH_COLUMN_PROPERTIES.map((property, ind) => {
                switch (property.apiValue) {
                  case 'id':
                    return (
                      <td key={`pm_col_${ind}`} data-testid={`pm_col_${ind}`}>
                        {item.id}
                      </td>
                    );
                  case 'typeId':
                    return (
                      <td key={`pm_col_${ind}`} data-testid={`pm_col_${ind}`}>
                        {item.type.name}
                      </td>
                    );
                  case 'giftReceiverId':
                    return (
                      <td key={`pm_col_${ind}`} data-testid={`pm_col_${ind}`}>
                        {usersList.find(c => c.id === item.giftReceiverId)?.firstName + ' ' +  usersList.find(c => c.id === item.giftReceiverId)?.lastName }
                      </td>
                    );
                  case 'amount':
                    return (
                        <td key={`pm_col_${ind}`} data-testid={`pm_col_${ind}`}>
                        {item.amount}
                      </td>
                    );
                  case 'balance':
                    return (
                      <td key={`pm_col_${ind}`} data-testid={`pm_col_${ind}`}>
                        <div className="flex align-middle justify-center">
                          <a
                            className="p-1 cursor-pointer icon"
                            data-testid={`pending_gifts_btn_${item.id}`}
                            onClick={() => declineGiftClick(item)}
                          >
                            <img src={deleteIcon} />
                          </a>
                        </div>
                      </td>
                    );
                }
              })}
            </tr>
          ))}
        </Table>
      </div>
            </div>
            {/*footer*/}
            <div className="flex items-center justify-center p-6 border-t border-solid border-blueGray-200 rounded-b">
              <button
                data-testid="cancel-button"
                className="bg-emerald-500 text-gray-800 active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-5 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={() => closeShowModal()}
              >
                OK
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
      {showRemoveGiftModal && (
        <ModalDialog
          modalText={`Are you sure you wish to decline gift with id: ${removeItem.id} from User balance?`}
          modalTitle={'DECLINE GIFT'}
          closeShowModal={() => {
            setShowRemoveGiftModal(false);
          }}
          confirmAction={() => { cancelPendingGifts(removeItem)}}
          confirmBtnText={'Remove'}
          showCancel={true}
        />
      )}
    </>
  );
};

export default PendingGiftsModal;
