import React, { FunctionComponent } from 'react';
import deleteIcon from '../../assets/images/delete-forever.png';
import editIcon from '../../assets/images/edit.png';
import viewIcon from '../../assets/images/view.png';
import ProductCatalogItem from '../../interfaces/product';
import { TimeZone } from '../../interfaces/timezone';
import TableColProperty from '../shared/interfaces/TableColProperty';
import Table from '../shared/Table';

//configuration array for the table body columns
const PM_COLUMN_PROPERTIES: TableColProperty[] = [
  { title: 'Product ID', colMaxWidth: 'auto', colMinWidth: 'auto', apiValue: 'id' },
  { title: 'Product Name', colMaxWidth: 'auto', colMinWidth: 'auto', apiValue: 'name' },
  { title: 'Product Description', colMaxWidth: 'auto', colMinWidth: '100px', apiValue: 'description' },
  { title: 'Additional Info', colMaxWidth: 'auto', colMinWidth: '150px', apiValue: 'additionalInfo' },
  { title: 'Status', colMaxWidth: 'auto', colMinWidth: '100px', apiValue: 'status' },
  { title: 'Property #1 Name', colMaxWidth: '70px', colMinWidth: 'auto', apiValue: 'propertyName1' },
  { title: 'Property #1 Value', colMaxWidth: '70px', colMinWidth: 'auto', apiValue: 'propertyValue1' },
  { title: 'Property #2 Name', colMaxWidth: '70px', colMinWidth: 'auto', apiValue: 'propertyName2' },
  { title: 'Property #2 Value', colMaxWidth: '70px', colMinWidth: 'auto', apiValue: 'propertyValue2' },
  { title: 'Property #3 Name', colMaxWidth: '70px', colMinWidth: 'auto', apiValue: 'propertyName3' },
  { title: 'Property #3 Value', colMaxWidth: '70px', colMinWidth: 'auto', apiValue: 'propertyValue3' },
  { title: 'Property #4 Name', colMaxWidth: '70px', colMinWidth: 'auto', apiValue: 'propertyName4' },
  { title: 'Property #4 Value', colMaxWidth: '70px', colMinWidth: 'auto', apiValue: 'propertyValue4' },
  { title: 'Property #5 Name', colMaxWidth: '70px', colMinWidth: 'auto', apiValue: 'propertyName5' },
  { title: 'Property #5 Value', colMaxWidth: '70px', colMinWidth: 'auto', apiValue: 'propertyValue5' },
  { title: 'Price', colMaxWidth: 'auto', colMinWidth: 'auto', apiValue: 'price' },
  { title: 'Sale Price', colMaxWidth: 'auto', colMinWidth: 'auto', apiValue: 'priceOnSale' },
  { title: 'Delivery Time', colMaxWidth: 'auto', colMinWidth: 'auto', apiValue: 'deliveryTime' },
  { title: 'Images', colMaxWidth: 'auto', colMinWidth: '150px', apiValue: 'thumbnail' },
  { title: 'Relevant for', colMaxWidth: 'auto', colMinWidth: '150px', apiValue: 'availableInZones' },
  { title: '"New" Label', colMaxWidth: 'auto', colMinWidth: 'auto', apiValue: 'newProduct' },
  { title: '"Must Have" Label', colMaxWidth: 'auto', colMinWidth: '100px', apiValue: 'mustHaveLabel' },
  { title: 'Action', colMaxWidth: 'auto', colMinWidth: '90px', apiValue: 'action_items' }
];

export type ProductManagementTableProps = {
  zones: TimeZone[];
  shownProducts: ProductCatalogItem[];
  onAdditionalImagesClick: (item: ProductCatalogItem) => void;
  setShowDeteleteModal: (b: boolean) => void;
  setDeleteItem: (item: ProductCatalogItem) => void;
  onViewClick: (item: ProductCatalogItem) => void;
  onEditClick: (item: ProductCatalogItem) => void;
};

const ProductManagementTable: FunctionComponent<ProductManagementTableProps> = props => {
  return (
    <div className="table-holder" data-testid="productManagementTable">
      {/* list of products in table section */}
      <Table columnProps={PM_COLUMN_PROPERTIES}>
        {props.shownProducts.map((item: ProductCatalogItem, index: number) => (
          <tr key={`pm_row_${index}`} data-testid="product_tr">
            {PM_COLUMN_PROPERTIES.map((property, ind) => {
              let displayText = 'N/A';
              switch (property.apiValue) {
                case 'propertyName1':
                case 'propertyName2':
                case 'propertyName3':
                case 'propertyName4':
                case 'propertyName5':
                  const numKey = parseInt(property.apiValue.substring(property.apiValue.length - 1, property.apiValue.length));
                  if (item.properties) {
                    const key_arr: string[] = Object.keys(item.properties);
                    if (key_arr[numKey - 1]) {
                      displayText = key_arr[numKey - 1];
                    }
                  }
                  return (
                    <td key={`pm_col_${ind}`} data-testid={`pm_col_${ind}`}>
                      {displayText}
                    </td>
                  );
                case 'propertyValue1':
                case 'propertyValue2':
                case 'propertyValue3':
                case 'propertyValue4':
                case 'propertyValue5':
                  const numVal = parseInt(property.apiValue.substring(property.apiValue.length - 1, property.apiValue.length));
                  if (item.properties) {
                    const val_arr: string[] = Object.values(item.properties);
                    if (val_arr[numVal - 1]) {
                      displayText = val_arr[numVal - 1];
                    }
                  }
                  return (
                    <td key={`pm_col_${ind}`} data-testid={`pm_col_${ind}`}>
                      {displayText}
                    </td>
                  );
                case 'thumbnail':
                  return (
                    <td key={`pm_col_${ind}`} data-testid={`pm_col_${ind}`}>
                      {item.thumbnail && <img src={item.thumbnail} />}
                      <div>
                        <a
                          className="cursor-pointer text-red hover:text-red_shadow"
                          data-testid="additional-images"
                          onClick={() => props.onAdditionalImagesClick(item)}
                        >
                          {' '}
                          Additional Images
                        </a>
                      </div>
                    </td>
                  );
                case 'availableInZones':
                  if (item.availableInZones.length === props.zones.length - 1) {
                    return (
                      <td key={`pm_col_${ind}`} data-testid={`pm_col_${ind}`}>
                        <div className="mb-3" key={`z_all`}>
                          All
                        </div>
                      </td>
                    );
                  } else {
                    return (
                      <td key={`pm_col_${ind}`} data-testid={`pm_col_${ind}`}>
                        {item.availableInZones.map((z, i) => (
                          <div className="mb-3" key={`z_${i}`}>
                            {z.name}
                          </div>
                        ))}
                      </td>
                    );
                  }
                case 'newProduct':
                  return (
                    <td key={`pm_col_${ind}`} data-testid={`pm_col_${ind}`}>
                      <label className="checkbox-container disabled">
                        {' '}
                        <input type="checkbox" checked={item.newProduct} readOnly data-testid={`new_product_checkbox`} />
                        <span className="checkmark"></span>
                      </label>
                    </td>
                  );
                case 'mustHaveLabel':
                  return (
                    <td key={`pm_col_${ind}`} data-testid={`pm_col_${ind}`}>
                      <label className="checkbox-container disabled">
                        {' '}
                        <input type="checkbox" checked={item.mustHaveLabel} readOnly data-testid={`must_have_checkbox`} />
                        <span className="checkmark"></span>
                      </label>
                    </td>
                  );
                case 'action_items':
                  return (
                    <td key={`pm_col_${ind}`} data-testid={`pm_col_${ind}`}>
                      <div className="flex align-middle">
                        <a
                          className="cursor-pointer"
                          data-testid="view_btn"
                          onClick={() => {
                            props.onViewClick(item);
                          }}
                        >
                          <img src={viewIcon} />
                        </a>
                        <a className="px-1 cursor-pointer" data-testid="edit_btn" onClick={() => props.onEditClick(item)}>
                          <img src={editIcon} />
                        </a>
                        <a
                          className="cursor-pointer"
                          data-testid="delete_btn"
                          onClick={() => {
                            props.setShowDeteleteModal(true);
                            props.setDeleteItem(item);
                          }}
                        >
                          <img src={deleteIcon} />
                        </a>
                      </div>
                    </td>
                  );
                default:
                  return (
                    <td key={`pm_col_${ind}`} data-testid={`pm_col_${ind}`}>
                      {item[property.apiValue as keyof ProductCatalogItem]}
                    </td>
                  );
              }
            })}
          </tr>
        ))}
      </Table>
    </div>
  );
};

export default ProductManagementTable;
