import React, { FunctionComponent } from 'react';
import { CKEditor, CKEditorEventHandler } from 'ckeditor4-react';

interface Props {
  textEditorContent: any;
  setTextEditorContent: any;
}

const RichTextEditor: FunctionComponent<Props> = props => {
  const configObj = {};

  return (
    <div>
      <CKEditor<{
        onChange: CKEditorEventHandler<'onChange'>;
      }>
        config={configObj}
        editorUrl="https://cdn.ckeditor.com/4.16.2/full-all/ckeditor.js"
        initData={props.textEditorContent}
        onChange={(event: any) => props.setTextEditorContent(event.editor.getData())}
        onInstanceReady={() => {}}
      />
    </div>
  );
};

export default RichTextEditor;
