import { AxiosRequestConfig, AxiosResponse } from 'axios';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useInterceptor } from '../../auth/interceptor';
import { TimeZone } from '../../interfaces/timezone';
import Unit from '../../interfaces/Unit';
import User, { UserItem } from '../../interfaces/user';
import useSettings from '../../stores/settings';
import DropDown from '../shared/custom_components/DropDown';
import GivePlayticoins from './GivePlayticoins';
import PendingGiftsModal from './PendingGifts';
import UserBalanceModal from './PlaytiCoinsBalanceHistory';
import PlayticoinsTable from './PlayticoinsTable';

const PlaytiCoinsManagement: FunctionComponent = () => {
  const [axiosApiInstance] = useInterceptor();
  const [selectedZone, setSelectedZone] = useState({} as TimeZone);
  const [zones, setZones] = useState([] as TimeZone[]);
  const [selectedUnit, setSelectedUnit] = useState({ id: '0', name: 'All studios/units', description: '' } as Unit);
  const [units, setUnits] = useState([] as Unit[]);
  const [allSelected, setAllSelected] = useState(false);
  const [fullname, setFullname] = useState('');
  const [email, setEmail] = useState('');
  const [usersList, setUsersList] = useState([] as UserItem[]);
  const [userBalanceModalOpen, setUserBalanceModalOpen] = useState(false);
  const [pendingGiftsModalOpen, setPendingGiftsModalOpen] = useState(false);
  const [currentlySelectedUser, setCurrentlySelectedUser] = useState({} as User);
  const { setSpinner } = useSettings();

  useEffect(() => {
    //get all available zones
    axiosApiInstance.get(`/settings/available-zones`).then(
      (res: AxiosResponse) => {
        setZones(res.data as TimeZone[]);
      },
      () => {
        toast.error('No zones available');
      }
    );
    //get all available studios/units
    axiosApiInstance.get(`/settings/studios-units`).then(
      (res: AxiosResponse) => {
        setUnits([selectedUnit, ...(res.data as Unit[])]);
      },
      () => {
        toast.error('No studio units available');
      }
    );
  }, []);

  //when users list change check if all users are checked or not
  useEffect(() => {
    if (usersList.length) {
      const all = usersList.find(el => !el.checked) ? false : true;
      setAllSelected(all);
    }
  }, [usersList]);

  //set selected zone
  // when zone is changed in the dropdown
  const selectZone = (zone: TimeZone | undefined) => {
    if (zone) {
      setSelectedZone(zone);
    }
  };

  //set selected unit
  // when unit is changed in the dropdown
  const selectUnit = (unit: Unit | undefined) => {
    if (unit) {
      setSelectedUnit(unit);
    }
  };

  //toggle one employee
  const selectItem = (item: UserItem) => {
    setUsersList(
      usersList.map((employee: UserItem) => {
        if (employee.id === item.id) {
          employee.checked = !employee.checked;
        }
        return employee;
      })
    );
  };

  // open playticoins balance history for employee
  const openBalanceHistory = (item: User) => {
    setCurrentlySelectedUser(item);
    setUserBalanceModalOpen(true);
  };

  // open pending gifts for employee
  const openPendingGifts = (item: User) => {
    setCurrentlySelectedUser(item);
    setPendingGiftsModalOpen(true);
  };

  //toggle 'select all' purchase orders
  const onSelectAllChange = () => {
    setUsersList(
      usersList.map((_item: UserItem) => {
        _item.checked = !allSelected;
        return _item;
      })
    );
    setAllSelected(!allSelected);
  };

  // load employes according to search criteria
  const loadUsers = () => {
    //add params to the request
    let reposneReceived = false;
    setSpinner(true);
    let timer: ReturnType<typeof setTimeout> | undefined = setTimeout(() => {
      if (reposneReceived) {
        setSpinner(false);
      }
      if (timer) {
        clearTimeout(timer);
      }
      timer = undefined;
    }, 500);
    const params = {
      zoneId: selectedZone.id,
      studioUnitId: selectedUnit.id ? selectedUnit.id : undefined,
      email: email === '' ? undefined : email,
      fullName: fullname === '' ? undefined : fullname,
    };
    axiosApiInstance.get(`/user/search`, { params } as AxiosRequestConfig).then(
      (res: AxiosResponse) => {
        if (!timer) {
          setSpinner(false);
        }
        if (res.data.items) {
          const users = res.data.items.map((e: User) => {
            return { ...e, checked: false };
          }) as UserItem[];
          setUsersList(users);
        }
        reposneReceived = true;
      },
      () => {
        if (!timer) {
          setSpinner(false);
        }
        reposneReceived = true;
        toast.error('No users available');
      }
    );
  };

  return (
    <div className="h-screen w-screen bg-white place-items-center px-10 py-12" data-testid="playtiCoinsManagement">
      {/* select zone section */}
      <div className="mb-6 grid gap-x-3 grid-cols-2 xl:grid-cols-3 items-center">
        <div className="grid gap-x-1 grid-cols-3 items-center">
          {' '}
          <span className="col-span-1">Office location </span>
          <div className="inline-block col-span-2" data-testid="zone-dropdown">
            <DropDown
              value={selectedZone.name}
              placeholder="Select zone"
              onChange={(v: string) => {
                selectZone(zones.find(z => z.name === v));
              }}
              options={zones.map(zone => zone.name)}
            />
          </div>
        </div>
        <div className="grid gap-x-1 grid-cols-4 items-center">
          <span className="col-span-1">Full Name </span>
          <input
            data-testid="fullname"
            className="input-text inline-block col-span-2"
            value={fullname}
            onChange={e => {
              setFullname(e.target.value);
            }}
          />
        </div>
      </div>

      {/* select status section*/}
      <div className="mb-10 grid gap-x-3 grid-cols-2 xl:grid-cols-3 items-center">
        <div className="grid gap-x-1 grid-cols-3 items-center">
          <span className="col-span-1">Studio/Unit </span>

          <div className="inline-block col-span-2" data-testid="studio-dropdown">
            <DropDown
              value={selectedUnit.name}
              placeholder="Select studio/unit"
              onChange={(v: string) => {
                selectUnit(units.find(u => u.name === v));
              }}
              options={units.map(unit => unit.name)}
            />
          </div>
        </div>
        <div className="grid gap-x-1 grid-cols-4 items-center">
          <span className="col-span-1">Email </span>
          <input
            data-testid="email"
            className="input-text inline-block col-span-2"
            value={email}
            onChange={e => {
              setEmail(e.target.value);
            }}
          />
          <div className="inline-block col-span-1">
            <span className="flex items-center justify-center text-center">
              <a
                data-testid="go_loadusers_btn"
                className={`btn-main pl-10 pr-10 ${selectedZone.id ? '' : ' disabled'}`}
                onClick={() => loadUsers()}
              >
                Go
              </a>
            </span>
          </div>
        </div>
      </div>
      {/* playticoins management table of users */}
      <PlayticoinsTable
        allSelected={allSelected}
        onSelectAllChange={onSelectAllChange}
        usersList={usersList}
        selectItem={selectItem}
        openBalanceHistory={openBalanceHistory}
        openPendingGifts={openPendingGifts}
      />
      {/* give playticoins section */}
      <GivePlayticoins usersList={usersList} />
      {/* model that is opened on clicking user balance history button for each user */}
      {userBalanceModalOpen && (
        <UserBalanceModal
          user={currentlySelectedUser}
          closeShowModal={() => {
            setUserBalanceModalOpen(false);
          }}
        />
      )}
      {/* model that is opened on clicking user pending gifts button for each user */}
      {pendingGiftsModalOpen && (
        <PendingGiftsModal
          user={currentlySelectedUser}
          usersList={usersList}
          closeShowModal={() => {
            setPendingGiftsModalOpen(false);
          }}
        />
      )}
    </div>
  );
};

export default PlaytiCoinsManagement;
